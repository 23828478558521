<template>
  <div
    class="bg-ash-100 bg-opacity-50 flex flex-col justify-center items-center p-5"
  >
    <div
      class="grid grid-cols-1 bg-white shadow w-full h-full max-w-1200 max-auto p-5"
      :style="isMobile ? null : {
        'max-height': '450px'
      }"
    >
      <!-- left -->
      <div
        class="flex flex-col items-center justify-center lg:py-0 py-10"
      >
        <DgIcon
          size="40"
          name="exclamation-circle"
          color="red-600"
          class="mb-6"
        />
        <p
          class="lg:text-3xl text-2xl text-ash-800 mb-5"
        >下載失敗</p>
        <p
          class="lg:text-sm text-xs text-ash-600 text-center leading-loose mb-6"
          :style="{
            'max-width': '360px'
          }"
        >文件已被刪除，無法下載</p>
        <router-link
          to="/"
          tag="button"
          class="border border-primary rounded-full"
        >
          <p
            class="lg:text-base text-sm text-primary px-7 py-2"
          >返回資料下載</p>
        </router-link>
      </div>
    </div>
    <div
      class="flex lg:flex-row flex-col items-center my-10"
    >
      <p
        class="lg:text-base text-sm text-ash-600"
      >如有任何其他緊急問題，歡迎撥打免付費電話</p>
      <a
        :href="`tel:${phoneNumber}`"
        class="flex items-center"
      >
        <DgIcon
          size="12"
          name="phone"
          color="ash-600"
          class="mx-1.5"
        />
        <p
          class="lg:text-base text-sm text-ash-600"
        >{{phoneNumber}}</p>
      </a>
    </div>
  </div>
</template>

<script>
import DgIcon from '@/components/base/DgIcon.vue';

export default {
  name: 'Error',
  inject: ['device'],
  components: {
    DgIcon,
  },
  data() {
    return {
      phoneNumber: '0800-888-162',
    };
  },
  computed: {
    isMobile() {
      return this.device.isMobile;
    },
  },
  methods: {

  },
};
</script>
